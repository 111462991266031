/* eslint-disable no-undef */
import React from "react"
import StructurePageAlbamar from "@components/pageCasosdeExito/template/templatePageAlbamar"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Albamar [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/ambamar/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Conoce cómo Albamar, líder en el desarrollo de proyectos inmobiliarios, ha mejorado su tiempo de respuesta en +60% gracias a la integración de sus puntos de contacto con sus herramientas internas." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Albamar" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/albamar/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-albamar.jpg`}
      />
      <meta property="og:description" content="Conoce cómo Albamar, líder en el desarrollo de proyectos inmobiliarios, ha mejorado su tiempo de respuesta en +60% gracias a la integración de sus puntos de contacto con sus herramientas internas." />
    </Helmet>
    <StructurePageAlbamar location={location} />
  </div>
)

export default IndexPage
