import React from "react"
import Foto from "@components/pageCasosdeExito/image/imgPhotoEmpresa/albamar/FotoAlbamar.png"
import Foto2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/albamar/FotoAlbamar2.png"

//logo de empresas clientes
import Limautos from "@components/pageCasosdeExito/image/logoscustomer/Albamar.svg"

//iconos de modulos
import canalVoz from "@components/pageCasosdeExito/image/iconcards/canalVoz.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/integrarApi.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import leads from "@components/pageCasosdeExito/image/iconcards/leads.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/albamar/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/albamar/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/albamar/img3.svg"

const text1 = (
  <span className="">
    Albamar, líder en el desarrollo de proyectos inmobiliarios, cuenta con más 20 años de experiencia en este sector. Ha entregado más de 35 edificios, consolidándose como un referente de calidad. Construye y vende sus propios inmuebles, con certificaciones como Best Place to Live, EDGE, LEED y Mi Vivienda Verde.
    <br /><br />
    Gracias a la integración de Beex con su CRM, Albamar ha logrado incrementar en 75% la conversión de citas a visitas gracias a la unificación de sus canales digitales con Beex. Esto ha permitido una gestión más eficiente y una atención al cliente más rápida y efectiva.
  </span>
)

const text2 = (
  <span>
    Gracias a su enfoque en la innovación y eficiencia, Albamar Grupo Inmobiliario ha potenciado sus operaciones digitales con la ayuda de Beex. Luis Angelo Aroni Huamán, Jefe de Contact Center en Albamar, ha liderado la implementación de la API de WhatsApp Business y la automatización en la atención al cliente, logrando optimizar la gestión de leads y la creación de campañas masivas.
    <br /><br />
    Albamar ha optimizado su estrategia digital mediante la integración de Beex Contact Center con su CRM, permitiendo una automatización total en la captación y seguimiento de clientes. Este enfoque ha garantizado una mayor eficiencia operativa, reduciendo tiempos de respuesta y facilitando una gestión ágil de oportunidades de negocio.
    <br /><br />
    Parte de sus objetivos es seguir mejorando la comunicación con sus clientes y la eficiencia en su proceso de prospección de leads. Como menciona Luis, Beex se ha convertido en la solución más confiable para centralizar sus canales digitales y optimizar el rendimiento de su equipo.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex nos permite mantener contacto constante con nuestros clientes a través de WhatsApp, con su API oficial. Al tener todos los canales integrados, nuestros asesores pueden ofrecer una atención más rápida y personalizada, lo que ha mejorado significativamente la experiencia del cliente.”
  </p>
)

const text5 = (
  <span>
    WhatsApp se ha convertido en el principal canal de contacto de Albamar gracias a la implementación de la API oficial de WhatsApp Business, proporcionada por Beex. Esta herramienta ha permitido automatizar la atención al cliente y proceso de ventas, reduciendo los tiempos de respuesta y mejorando la eficiencia operativa.
    <br /><br />
    De hecho, a nivel de prospección, tenemos bots configurados para recolectar datos de nuestros leads y que las mejores oportunidades puedan avanzar de manera nutrida hacia nuestros vendedores. Además, el uso de plantillas de mensajes para WhatsApp ha facilitado la gestión del envío de campañas masivas y ha asegurado la consistencia en la comunicación.
    <br /><br />
    Como resultado, Albamar ha logrado mantener una interacción fluida y personalizada con sus clientes potenciales, incrementando la satisfacción y optimizando sus procesos de atención.
  </span>
)

const text6 = (
  <span>
    “Esta versión oficial de WhatsApp para el sector inmobiliario ha sido fundamental para nosotros. Nos ha permitido ofrecer un servicio más rápido y efectivo a nuestros clientes, mejorando la comunicación y logrando un aumento del +75% en la conversión de citas a visitas.”

  </span>
)
const text7 = (
  <span>
    Albamar ha optimizado sus procesos de marketing y ventas mediante la integración de su CRM (Quiven) con Beex Contact Center a través integración con nuestra API Cloud. Esta integración permite automatizar el primer contacto con los leads generados desde anuncios, mejorando la eficiencia y acelerando la respuesta inicial.
    <br /><br />
    La plataforma de Beex gestiona múltiples canales como WhatsApp, correo, telefonía y SMS en una sola interfaz, lo que ha permitido al equipo de Albamar mejorar su productividad y ofrecer una experiencia de cliente más fluida.

  </span>
)

const text8 = (
  <span>
    “La integración de Beex con nuestro CRM nos ha permitido automatizar el primer contacto con los leads, asegurando respuestas rápidas y personalizadas. Esto ha optimizado nuestro proceso de ventas y mejorado la eficiencia de nuestro equipo. De hecho, hemos mejorado en +60% los tiempos de respuesta de nuestra operación con nuestros clientes.”
  </span>
)
const text9 = (
  <span>
    Albamar ha optimizado su generación de leads mediante anuncios digitales que se integran directamente en su CRM y gestionan el contacto y comunicación con Beex Contact Center. A través de Beex, estos leads reciben un primer contacto automatizado en cuestión de segundos, asegurando una respuesta rápida y eficiente.
    <br /><br />
    Gracias a la automatización del proceso y la integración con Beex, Albamar ha logrado maximizar la efectividad de sus anuncios, asegurando un seguimiento inmediato y aumentando la tasa de conversión de prospectos a clientes.

  </span>
)

const text10 = (
  <span>
    El soporte y acompañamiento de Beex ha sido clave para Albamar durante todo el proceso de implementación y uso de la plataforma. Cada vez que se presentó una incidencia, la solución fue rápida y efectiva, permitiendo mantener la continuidad en la atención al cliente.
    <br /><br />
    Además, el equipo de Albamar destaca la facilidad de integración con otros CRM mediante la API de Beex y la rapidez en la respuesta del soporte técnico.
    <br /><br />
    Sin duda, Albamar recomienda Beex por su acompañamiento constante, soporte inmediato y la facilidad de uso de su plataforma.
  </span>
)
const text11 = (
  <span>
    “Durante este tiempo, el soporte de Beex ha sido excepcional. La solución a cualquier problema es casi inmediata y la comunicación constante nos ha permitido optimizar nuestros procesos sin interrupciones. Además, la plataforma es intuitiva y el soporte en línea es rápido, sin tiempos de espera mayores a 12 horas”.
  </span>
)

const text12 = (
  <span>
    Estos son algunos de los procesos con los que Beex ha ayudado a Albamar Grupo Inmobiliario en su operación de atención al cliente, marketing y ventas.

  </span>
)

const text13 = (
  <p style={{ marginBottom: "70px" }}>
    A Beex y Albamar los une la visión de transformar los procesos tradicionales de gestión inmobiliaria. En el caso de Albamar, mejorar la experiencia de sus clientes y optimizar la comunicación ha sido clave. Estamos muy felices de brindar herramientas que apoyen ese proceso.

  </p>
)

const data = {
  intro: {
    image: Limautos,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: Foto,
    text4: "",
    title5: "WhatsApp para el sector inmobiliario",
    text5: text5,
    text6: text6,
    title7: "Integración de canales digitales y voz",
    text7: text7,
    text8: text8,
    title9: "Mejora en resultados de anuncios (Ads)",
    text9: text9,
    fototwo: Foto2,
    title10: "Soporte y acompañamiento de Beex",
    text10: text10,
    title11: "Acompañamiento y respaldo que se siente",
    text11: text11,
    title12: "Monitoreo de calidad con el API de WhatsApp Business",
    text12: text12,
    text13: text13,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Envío de campañas masivas de WhatsApp para marketing y actualizaciones de proyectos. Con Beex, Albamar gestiona estos envíos de manera rápida y eficiente, mejorando la comunicación y aumentando la tasa de respuesta.",
    },
    {
      title: "Canal de voz",
      img: canalVoz,
      info:
        "Llamadas automáticas y personalizadas para contactar clientes potenciales. Albamar utiliza Beex para realizar marcaciones predictivas y progresivas, optimizando su proceso de ventas, especialmente post registro de leads en formularios.",
    },
  
    {
      title: "Leads",
      img: leads,
      info:
        "Generación de leads a través de anuncios que se integran automáticamente en su CRM (Quiven) mediante APIs. Desde allí, Beex facilita la automatización del primer contacto, optimizando el proceso de prospección y seguimiento.",
    },
    {
      title: "Integraciones vía API",
      img: gestion,
      info:
        "Beex permite a Albamar integrar su CRM con Beex Contact Center a través de APIs, facilitando la automatización de procesos y mejorando la eficiencia en la gestión de clientes.",
    },
  ],
}

export default data
